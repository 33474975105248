import anonymousOrder from './anonymous-order';
export default class Flatpage {

  constructor() {
    this.init();
  }

  init(){
    this.container = $('.black-friday-container');
    this.containerTwo = $('.black-friday-container-two');
    this.catalogSlider = $('.js-catalog-slider');
    this.collectionSlider = $('.js-collection-slider');

    let catalogSliderProp = {
      item: 4,
      loop: true,
      pager: false,
      galleryMargin: 20,
      autoWidth: false,
      controls: true,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            item: 3
          }
        },
        {
          breakpoint: 768,
          settings: {
            item: 2
          }
        },
        {
          breakpoint: 575,
          settings: {
            item: 2
          }
        },
      ]
    }

    let collectionSliderProp = {
      item: 3,
      loop: true,
      pager: false,
      galleryMargin: 30,
      autoWidth: false,
      controls: false,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            item: 4
          }
        },
        {
          breakpoint: 768,
          settings: {
            item: 4,
            autoWidth:true
          }
        },
        {
          breakpoint: 575,
          settings: {
            item: 2,
            autoWidth:false,
          }
        },
      ]
    };

    this.initSidebar();
    this.initSlider(this.catalogSlider , catalogSliderProp);
    this.initSlider(this.collectionSlider , collectionSliderProp)

    this.onClickReadMore();
    this.onClickReadMoreTwo();
    anonymousOrder();
  }

  initSidebar(){
    if($('.js-sidebar-menu').length){
      const path = window.location.pathname + window.location.search ;
      $('.js-sidebar-menu li').each(function(){
        const self = $(this);
        const href = self.children().attr('href');
        if( href === path){
          self.addClass('active');
        }
      })
    }
  }

  initSlider(selector,properties){
    if (!selector && !selector.length) return;
    selector.lightSlider({...properties});
  }

  onClickReadMore() {
    let $listDescriptionWrapper = this.container.find('.js-list-description-text');
    if ($listDescriptionWrapper.length > 0) {
      let $maxLength = 475;
      let $fullContent = $listDescriptionWrapper.html();
  
      if ($fullContent.length > $maxLength) {
        let short_content = $fullContent.substr(0, $maxLength);
  
        let short_html = `
          <span class="short_content">${short_content}</span>...&nbsp;&nbsp;
          <a href="javascript:;" class="js-read-more read-more">Devamını Oku</a>
        `;
        let long_html = `
          <span class="full_content">${$fullContent}</span>
          <a href="javascript:;" class="js-read-more read-less show">Devamını Gizle</a>
        `;
  
        $listDescriptionWrapper.html(short_html);
  
        this.container.on('click', '.js-read-more', () => {
          if ($listDescriptionWrapper.hasClass('show')) {
            $listDescriptionWrapper.html(short_html);
          } else {
            $listDescriptionWrapper.html(long_html);
          }
          $listDescriptionWrapper.toggleClass('show');
        });
      }
    }
  }

  onClickReadMoreTwo(){
    let $listDescriptionWrapper = this.containerTwo.find('.js-list-description-text-two');
    if($($listDescriptionWrapper).length > 0){
      let $maxLength = 475;
      let $fullContent = $($listDescriptionWrapper).html();
      if($fullContent.length>$maxLength) {
        let short_content =  $($fullContent).html().substr( 0, $maxLength );
        let long_content = $($fullContent).html();
        let short_html = `<span class="short_content">${short_content}</span>...&nbsp;&nbsp;<a href="javascript:;" class="js-read-more read-more">Devamını Oku</a>`;
        let long_html = `<span class="short_content">${long_content}</span><a href="javascript:;" class="js-read-more read-more">Devamını Gizle</a>`;
        $listDescriptionWrapper.html(short_html);
        let $readMore = this.container.find('.js-read-more');
  
        $(document).on('click', $readMore,() => {
          if($listDescriptionWrapper.hasClass('show')) {
            $listDescriptionWrapper.html(short_html);
          }
          else $listDescriptionWrapper.html(long_html); {
            $listDescriptionWrapper.toggleClass('show');
          }
        })
      }
    } 
  }
}